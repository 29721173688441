import { render, staticRenderFns } from "./Nlist.vue?vue&type=template&id=536b2646&scoped=true&"
import script from "./Nlist.vue?vue&type=script&lang=js&"
export * from "./Nlist.vue?vue&type=script&lang=js&"
import style0 from "./Nlist.vue?vue&type=style&index=0&id=536b2646&lang=scss&scoped=true&"
import style1 from "./Nlist.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536b2646",
  null
  
)

export default component.exports